.file-grid {
  background-color: #ebebeb;
  border: 2px dashed grey;
  border-radius: 4px;
}

.file-grid__drop-zone {
  padding: 15px 0;
  cursor: pointer;
}

.file-grid__files {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  padding: 0 15px;
}

.file-grid__text {
  font-size: 12px;
  margin: 0;
}

.file-grid__instructions p {
  text-align: center;
  margin: 0;
}

.file-grid__instructions + .file-grid__files {
  margin-top: 15px;
}

.file-grid__files + .file-grid__instructions {
  /*margin-top: 15px;*/
}
