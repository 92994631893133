.radio-button-vinculo {
  gap: 80px !important;
}

@media (max-width: 1350px) {
  .radio-button-vinculo {
    gap: 30px !important;
  }
}

@media (max-width: 1286px) {
  .radio-button-vinculo {
    gap: 20px !important;
  }
}

@media (max-width: 1240px) {
  .radio-button-vinculo {
    gap: 15px !important;
  }
}

@media (max-width: 1205px) {
  .radio-button-vinculo {
    display: inline !important;
  }
}
