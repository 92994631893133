.label-origem-info {
  color: blue;
  font-size: 12px;
  margin-top: -20px;

  @media (max-width: 1279px) {
    margin-top: -42px;
  }
  @media (max-width: 995px) {
    margin-top: -85px;
  }

  /* @media (max-width: 970px) {
    margin-top: -80px;
  } */
  @media (max-width: 770px) {
    margin-top: -85px;
  }
}
